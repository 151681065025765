.base {
  @apply border border-grey-400 flex px-5 py-3 font-semibold leading-[23.2px];
}

.input {
  @apply rounded-full;
}

.textarea {
  @apply rounded-3xl;
}
