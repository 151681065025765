@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

body {
  margin: 0;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-grey-900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* font-family for cookieWow banner */
.cwc-cookie-banner-ui-sdk,
.cwc-cookie-banner-ui-sdk button,
.cwc-cookie-banner-ui-sdk span,
.cwc-cookie-banner-ui-sdk a {
  font-family: 'Prompt', sans-serif !important;
}
